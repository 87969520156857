// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import SupportMemberSearchRequestDTO from '../models/SupportMemberSearchRequestDTO';
import SupportMemberDetailDTO from '../models/SupportMemberDetailDTO';
import RelationDTO from '../models/RelationDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SupportMemberSearchDTO from '../models/SupportMemberSearchDTO';
import ResultDTO from '../models/ResultDTO';

export class SupportApiService extends BaseApi {

    // get: api/support/GetClients
    public getClients(): Promise<RelationDTO[]> {
        let url = `api/support/GetClients`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RelationDTO[]>(null, 'get', url, true, false);
    }

    // post: api/support/SearchMembers
    public searchMembers(dto: SupportMemberSearchRequestDTO): Promise<TableResponseDTO<SupportMemberSearchDTO>> {
        let url = `api/support/SearchMembers`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<SupportMemberSearchDTO>>(dto, 'post', url, true, false);
    }

    // get: api/support/GetSupportMember?clientId=${clientId}&userId=${userId}&pinId=${pinId}
    public getSupportMemberDetail(clientId: number, userId: number | null, pinId: number | null): Promise<SupportMemberDetailDTO> {
        let url = `api/support/GetSupportMember`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }
        if (pinId != null) {
            url += `${prefix}pinId=${pinId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SupportMemberDetailDTO>(null, 'get', url, true, false);
    }

    // post: api/support/SaveSupportMember
    public saveSupportMemberDetail(dto: SupportMemberDetailDTO): Promise<boolean> {
        let url = `api/support/SaveSupportMember`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(dto, 'post', url, true, false);
    }

    // post: api/support/ResendIndividualPinNotification?email=${encodeURIComponent(email)}&pin=${encodeURIComponent(pin)}
    public resendIndividualPinNotificationEmail(email: string, pin: string): Promise<ResultDTO> {
        let url = `api/support/ResendIndividualPinNotification`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (email != null) {
            url += `${prefix}email=${encodeURIComponent(email)}`;
            prefix = '&';
        }
        if (pin != null) {
            url += `${prefix}pin=${encodeURIComponent(pin)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
    }

    // post: api/support/SendRegistrationEmail?email=${encodeURIComponent(email)}
    public sendRegistrationEmail(email: string): Promise<boolean> {
        let url = `api/support/SendRegistrationEmail`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (email != null) {
            url += `${prefix}email=${encodeURIComponent(email)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'post', url, true, false);
    }
}
var service = new SupportApiService();
export default service;
