// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface MailroomFileSetupDTO { 
    id: number;
    account: string | null;
    fileType: string | null;
    inUrl: string | null;
    outUrl: string | null;
    mailroomAccountId: number | null;
    source: string | null;
    destination: string | null;
    spoSiteId: string | null;
    spoSiteUrl: string | null;
    fileExtension: string | null;
    failedDestination: string | null;
    failedDestinationConnection: string | null;
    excludeInvoiceFiles: boolean | null;
    active: boolean;
    incoming: boolean;
}
const MailroomFileSetupDTO = {
    create: (initValues?: Partial<MailroomFileSetupDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            account: null,
            fileType: null,
            inUrl: null,
            outUrl: null,
            mailroomAccountId: null,
            source: null,
            destination: null,
            spoSiteId: null,
            spoSiteUrl: null,
            fileExtension: null,
            failedDestination: null,
            failedDestinationConnection: null,
            excludeInvoiceFiles: null,
            active: false,
            incoming: false,
        },
        initValues);
    }
};

export default MailroomFileSetupDTO;