import { Breadcrumb, Button, Checkbox, Col, Form, Input, Layout, notification, Row, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import MailroomApiService from '../../api/MailroomApiService';
import PageTitle from '../../components/shared/PageTitle';
import PermissionGuard from '../../components/shared/PermissionGuard';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import useHasPermission from '../../hooks/useHasPermission';
import HistoryUtil from '../../utils/HistoryUtil';
import PageStayPrompt from '../../utils/PageStayPrompt';
import confirmModal from '../../utils/Modal';
import MailroomFileSetupDTO from '../../models/MailroomFileSetupDTO';
import MailroomAccountDetailDTO from '../../models/MailroomAccountDetailDTO';

interface RouteProps {
  accountId: string;
  fileSetupId?: string;
}

const MailroomFileSetupDetailPage = () => {
  const routeProps = useParams<RouteProps>();
  const [loading, setLoading] = useState(true);

  const idChanged = () => {
    if (routeProps.fileSetupId === 'new') {
      return 0;
    }

    const parsedId = parseInt(routeProps.fileSetupId ?? '0');

    return parsedId;
  };

  const [mailroomFileSetupId, setMailroomFileSetupId] = useState<number>(idChanged());
  const [mailroomFileSetup, setMailroomFileSetup] = useState<MailroomFileSetupDTO>(MailroomFileSetupDTO.create());
  const [mailroomAccount, setMailroomAccount] = useState<MailroomAccountDetailDTO>(MailroomAccountDetailDTO.create());
  const [changesPending, setChangesPending] = useState(false);
  const [form] = Form.useForm();
  const hasPermission = useHasPermission();

  const onChange = () => {
    if (!changesPending) {
      setChangesPending(true);
    }
  };

  const onSave = (values: any) => {
    setLoading(true);

    const model = {
      ...values,
      id: mailroomFileSetupId,
      mailroomAccountId: routeProps.accountId,
    } as MailroomFileSetupDTO;

    MailroomApiService.saveMailroomFileSetup(model)
      .then((res) => {
        setChangesPending(false);
        setMailroomFileSetupId(res);
        fetchMailroomFileSetup(res);
        notification.success({ message: 'Mailroom File Setup Saved' });
      })
      .catch((err) => {
        notification.error({ message: err.message, description: err.description });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchMailroomFileSetup = (id: number) => {
    if (id != null && id != 0) {
      MailroomApiService.getMailroomFileSetup(id)
        .then((fileSetup) => {
          setMailroomFileSetup(fileSetup);
        })
        .catch((err) => {
          notification.error({ message: 'Failed to load Mailroom File Setup details.', description: err?.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const fetchMailroomAccount = (id: number) => {
    if (id != null && id != 0) {
      MailroomApiService.getMailroomAccountDetail(id)
        .then((account) => {
          setMailroomAccount(account);
        })
        .catch((err) => {
          notification.error({ message: 'Failed to load Mailroom Account details.', description: err?.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setMailroomFileSetupId(idChanged());
  }, [routeProps.fileSetupId]);

  useEffect(() => {
    fetchMailroomAccount(parseInt(routeProps.accountId));
  }, [routeProps.accountId]);

  useEffect(() => {
    fetchMailroomFileSetup(mailroomFileSetupId);
  }, [mailroomFileSetupId]);

  useEffect(() => {
    form.resetFields();
    setChangesPending(false);
  }, [mailroomFileSetup]);

  const title =
    !loading && mailroomFileSetupId != 0
      ? `${mailroomAccount?.account ?? ''} - ${mailroomFileSetup?.fileType ?? ''}`.trim()
      : 'New File Setup';

  const readOnly = !hasPermission(Permission.UTILITIES_MAILROOM_FILESETUP_EDIT);

  return (
    <Layout.Content className="content-padding">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={Routes.generate(Routes.MAILROOM_ACCOUNT_LIST)}>Mailroom Accounts</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            to={Routes.generate(Routes.MAILROOM_ACCOUNT_DETAIL, {
              id: routeProps.accountId,
              tab: 'fileSetups',
            })}
          >
            {mailroomAccount.account} - {mailroomAccount.partner}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{mailroomFileSetupId == 0 ? 'New File Setup' : mailroomFileSetup.fileType}</Breadcrumb.Item>
      </Breadcrumb>
      <PageTitle title={title} style={{ marginTop: '0.5em', marginBottom: '0.5em' }} />
      <Form
        form={form}
        layout="vertical"
        onFinish={onSave}
        onValuesChange={onChange}
        style={{ width: '100%' }}
        initialValues={{
          ...mailroomFileSetup,
        }}
      >
        <Row justify="end">
          <Col>
            <PermissionGuard permissionId={Permission.UTILITIES_MAILROOM_FILESETUP_EDIT}>
              <Space>
                <Button
                  onClick={() =>
                    confirmModal('Are you sure you want to reset all changes?', () =>
                      fetchMailroomFileSetup(mailroomFileSetupId)
                    )
                  }
                  ghost
                  shape="round"
                  disabled={!changesPending}
                >
                  Reset
                </Button>
                <Button
                  ghost
                  htmlType="button"
                  shape="round"
                  onClick={() =>
                    HistoryUtil.push(
                      Routes.generate(Routes.MAILROOM_ACCOUNT_DETAIL, {
                        id: routeProps.accountId,
                        tab: 'fileSetups',
                      })
                    )
                  }
                >
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary" shape="round" disabled={!changesPending}>
                  Save Details
                </Button>
              </Space>
            </PermissionGuard>
          </Col>
        </Row>

        <Spin spinning={loading}>
          <PageStayPrompt
            when={changesPending}
            rootRoute={Routes.generate(Routes.MAILROOM_ACCOUNT_DETAIL, {
              id: routeProps.accountId,
              tab: 'fileSetups',
            })}
          />
        </Spin>
        <Row gutter={16}>
          <Col sm={6}>
            <Form.Item label="File Type" name="fileType" rules={[{ required: true, message: 'Required' }]}>
              <Input disabled={readOnly} />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item label="File Extension" name="fileExtension" rules={[{ required: true, message: 'Required' }]}>
              <Input disabled={readOnly} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={6}>
            <Form.Item label="Source" name="source" rules={[{ required: true, message: 'Required' }]}>
              <Input disabled={readOnly} />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item label="Source Url" name="inUrl" rules={[{ required: true, message: 'Required' }]}>
              <Input disabled={readOnly} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={6}>
            <Form.Item label="Destination" name="destination" rules={[{ required: true, message: 'Required' }]}>
              <Input disabled={readOnly} />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item label="Destination Url" name="outUrl" rules={[{ required: true, message: 'Required' }]}>
              <Input disabled={readOnly} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={6}>
            <Form.Item label="SPO Site Id" name="spoSiteId">
              <Input disabled={readOnly} />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item label="SPO Site Url" name="spoSiteUrl">
              <Input disabled={readOnly} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={6}>
            <Form.Item label="Failed Destination" name="failedDestination">
              <Input disabled={readOnly} />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item label="Failed Destination Connection" name="failedDestinationConnection">
              <Input disabled={readOnly} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={6}>
            <Form.Item label="Exclude Invoice Files" name="excludeInvoiceFiles" valuePropName="checked">
              <Checkbox disabled={readOnly} />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item
              label="Active"
              name="active"
              valuePropName="checked"
              rules={[{ required: true, message: 'Required' }]}
            >
              <Checkbox disabled={readOnly} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={6}>
            <Form.Item
              label="Incoming"
              name="incoming"
              valuePropName="checked"
              rules={[{ required: true, message: 'Required' }]}
            >
              <Checkbox disabled={readOnly} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Layout.Content>
  );
};

export default MailroomFileSetupDetailPage;
