import React, { Suspense } from 'react';
import ReactGA from 'react-ga';
import { Route, RouteProps, Switch } from 'react-router';
import './App.less';
import { AuthenticationProvider } from './auth/AuthenticationContext';
import MemberCardsDetailPage from './components/memberCard/MemberCardsDetailPage';
import Permission from './consts/Permission';
import Routes from './consts/Routes';
import AuthorizedLayout from './layouts/AuthorizedLayout';
import GuestLayout from './layouts/GuestLayout';
import PublicLayout from './layouts/PublicLayout';
import AccessGatePage from './pages/account/AccessGatePage';
import ConfirmEmailChangePage from './pages/account/ConfirmEmailChangePage';
import ConfirmEmailPage from './pages/account/ConfirmEmailPage';
import LoginPage from './pages/account/LoginPage';
import ProfilePage from './pages/account/ProfilePage';
import RegisterPage from './pages/account/RegisterPage';
import RequestResetPasswordPage from './pages/account/RequestResetPasswordPage';
import ResendConfirmationEmailPage from './pages/account/ResendConfirmationEmailPage';
import ResetPasswordPage from './pages/account/ResetPasswordPage';
import BrokerDetailPage from './pages/admin/BrokerDetailPage';
import BrokerListPage from './pages/admin/BrokerListPage';
import ClientsPage from './pages/admin/ClientsPage';
import CommunicationTesterPage from './pages/admin/CommunicationTesterPage';
import GuestAccessPage from './pages/admin/GuestAccessPage';
import PermissionAuditPage from './pages/admin/PermissionAuditPage';
import PermissionsPage from './pages/admin/PermissionsPage';
import ProvidersListPage from './pages/admin/ProvidersListPage';
import TpaDetailPage from './pages/admin/TpaDetailPage';
import TpaListPage from './pages/admin/TpaListPage';
import UserDetailPage from './pages/admin/UserDetailPage';
import UserListPage from './pages/admin/UserListPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import FailedMemberRegistrationsPage from './pages/support/FailedMemberRegistrationsPage';
import BalanceBillPage from './pages/tools/BalanceBillPage';
import MemberCardsPage from './pages/tools/MemberCardsPage';
import PaveTheWayToolPage from './pages/tools/PaveTheWayToolPage';
import ClientListPage from './pages/clients/ClientListPage';
import ProviderNominationPage from './pages/tools/ProviderNominationPage';
import HistoryUtil from './utils/HistoryUtil';
import LazyLoadingWaiter from './utils/LazyLoadingWaiter';
import { RedirectAs404 } from './utils/RouteErrors';
import EducationPage from './pages/education/EducationPage';
import DocumentListPage from './pages/documents/DocumentListPage';
import ClaimSearchPage from './pages/tools/ClaimSearchPage';
import Unauthorized from './auth/Unauthorized';
import DpcListPage from './pages/admin/DpcListPage';
import DpcDetailPage from './pages/admin/DpcDetailPage';
import OtherCompanyListPage from './pages/admin/OtherCompanyListPage';
import OtherCompanyDetailPage from './pages/admin/OtherCompanyDetailPage';
import DocumentViewer from './components/documents/DocumentViewer';
import PricingToolPage from './pages/tools/PricingToolPage';
import DocScanPage from './pages/utilities/DocScanPage';
import MailroomFileListPage from './pages/utilities/MailroomFileListPage';
import MailroomFileDetailPage from './pages/utilities/MailroomFileDetailPage';
import MailroomAccountPage from './pages/utilities/MailroomAccountPage';
import MemberSearchPage from './pages/support/MemberSearchPage';
import MemberDetailPage from './pages/support/MemberDetailPage';
import NominationSearchPage from './pages/tools/NominationSearchPage';
import MailroomFileSetupDetailPage from './pages/utilities/MailroomFileSetupDetailPage';

const ProviderMapPage = React.lazy(() => import('./pages/tools/ProviderMapPage'));
const ReportPage = React.lazy(() => import('./pages/reports/ReportPage'));

ReactGA.initialize($gaKey);

interface BaseRouteProps extends RouteProps {
  child?: React.ReactNode;
}

interface AuthRouteProps extends BaseRouteProps {
  requiredPermissions?: number[];
}

const App = () => {
  HistoryUtil.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const BaseRoute = (props: BaseRouteProps) => <Route {...props} render={() => <>{props.child}</>} />;
  const PublicRoute = (props: BaseRouteProps) =>
    BaseRoute({ ...props, child: <PublicLayout>{props.child}</PublicLayout> });

  const AuthRoute = (props: AuthRouteProps) =>
    BaseRoute({
      ...props,
      child: <AuthorizedLayout requiredPermissions={props.requiredPermissions}>{props.child}</AuthorizedLayout>,
    });

  return (
    <AuthenticationProvider>
      <Switch>
        {AuthRoute({ path: Routes.HOME_ROUTE, exact: true, child: <DashboardPage /> })}
        {/* Begin Public Pages */}
        {PublicRoute({ path: Routes.LOGIN, child: <LoginPage /> })}
        {PublicRoute({ path: Routes.GATE, child: <AccessGatePage /> })}
        {PublicRoute({ path: Routes.REGISTER, child: <RegisterPage /> })}
        {PublicRoute({ path: Routes.REQUEST_RESET_PASSWORD, child: <RequestResetPasswordPage /> })}
        {PublicRoute({ path: Routes.RESET_PASSWORD_LINK, child: <ResetPasswordPage /> })}
        {PublicRoute({ path: Routes.CONFIRM_EMAIL, child: <ConfirmEmailPage /> })}
        {PublicRoute({ path: Routes.RESEND_CONFIRMATION_EMAIL, child: <ResendConfirmationEmailPage /> })}
        {PublicRoute({ path: Routes.CONFIRM_EMAIL_CHANGE, child: <ConfirmEmailChangePage /> })}
        {PublicRoute({ path: Routes.UNAUTHORIZED, child: <Unauthorized /> })}
        {BaseRoute({ path: Routes.GUEST_BASE, child: <GuestLayout /> })}
        {BaseRoute({ path: Routes.GUEST_BASE_ALT, child: <GuestLayout /> })}
        {/* End Public Pages */}
        {AuthRoute({ path: Routes.CLIENTS_BASE, child: <ClientListPage /> })}
        {AuthRoute({
          path: Routes.ADMIN_CLIENTS,
          requiredPermissions: [Permission.ADMIN_CLIENT_LIST_VIEW],
          child: <ClientsPage />,
        })}
        {AuthRoute({
          path: Routes.ADMIN_GUEST_ACCESS,
          requiredPermissions: [Permission.ADMIN_EMPLOYERPINACCESS_VIEW],
          child: <GuestAccessPage />,
        })}
        {AuthRoute({
          path: Routes.PROVIDER_DIRECTORY,
          exact: true,
          requiredPermissions: [Permission.TOOLS_PROVIDERDIRECTORY_MAPVIEWBASE],
          child: (
            <Suspense fallback={<LazyLoadingWaiter />}>
              <ProviderMapPage />
            </Suspense>
          ),
        })}
        {AuthRoute({
          path: Routes.EDUCATION_BASE,
          exact: true,
          requiredPermissions: [Permission.GENERAL_EDUCATION_ACCESS],
          child: <EducationPage />,
        })}
        {AuthRoute({
          path: Routes.ADMIN_PROVIDER_LIST,
          exact: true,
          requiredPermissions: [Permission.ADMIN_PROVIDER_LIST_VIEW],
          child: <ProvidersListPage />,
        })}
        {AuthRoute({
          path: Routes.MEMBER_CARDS,
          exact: true,
          requiredPermissions: [Permission.TOOLS_MEMBERCARD_ACCESS],
          child: <MemberCardsPage />,
        })}
        {AuthRoute({
          path: Routes.MEMBER_CARDS_DETAIL,
          exact: true,
          requiredPermissions: [Permission.TOOLS_MEMBERCARD_ACCESS],
          child: <MemberCardsDetailPage />,
        })}
        {AuthRoute({
          path: Routes.PROVIDER_NOMINATION,
          exact: true,
          requiredPermissions: [Permission.TOOLS_PROVIDERNOMINATION_ACCESS],
          child: <ProviderNominationPage />,
        })}
        {AuthRoute({
          path: Routes.BALANCE_BILL,
          exact: true,
          requiredPermissions: [Permission.TOOLS_BALANCEBILL_ACCESS],
          child: <BalanceBillPage />,
        })}
        {AuthRoute({
          path: Routes.ADMIN_TPA_LIST,
          exact: true,
          requiredPermissions: [Permission.ADMIN_TPA_LIST_VIEW],
          child: <TpaListPage />,
        })}
        {AuthRoute({
          path: Routes.ADMIN_TPA_DETAILS,
          requiredPermissions: [Permission.ADMIN_TPA_DETAILS_VIEW],
          child: <TpaDetailPage />,
        })}
        {AuthRoute({
          path: Routes.ADMIN_DPC_LIST,
          exact: true,
          requiredPermissions: [Permission.ADMIN_DPC_LIST_VIEW],
          child: <DpcListPage />,
        })}
        {AuthRoute({
          path: Routes.ADMIN_DPC_DETAILS,
          requiredPermissions: [Permission.ADMIN_DPC_DETAILS_VIEW],
          child: <DpcDetailPage />,
        })}
        {AuthRoute({
          path: Routes.ADMIN_OTHERCOMPANY_LIST,
          exact: true,
          requiredPermissions: [Permission.ADMIN_OTHERCOMPANY_LIST_VIEW],
          child: <OtherCompanyListPage />,
        })}
        {AuthRoute({
          path: Routes.ADMIN_OTHERCOMPANY_DETAILS,
          requiredPermissions: [Permission.ADMIN_OTHERCOMPANY_DETAILS_VIEW],
          child: <OtherCompanyDetailPage />,
        })}
        {AuthRoute({
          path: Routes.ADMIN_BROKER_LIST,
          exact: true,
          requiredPermissions: [Permission.ADMIN_BROKER_LIST_VIEW],
          child: <BrokerListPage />,
        })}
        {AuthRoute({
          path: Routes.ADMIN_BROKER_DETAILS,
          requiredPermissions: [Permission.ADMIN_BROKER_DETAILS_VIEW],
          child: <BrokerDetailPage />,
        })}
        {AuthRoute({
          path: Routes.ADMIN_USER_LIST,
          exact: true,
          requiredPermissions: [Permission.ADMIN_USERS_LIST_VIEW],
          child: <UserListPage />,
        })}
        {AuthRoute({
          path: Routes.ADMIN_USER_DETAIL,
          requiredPermissions: [Permission.ADMIN_USERS_DETAILS_VIEW],
          child: <UserDetailPage />,
        })}
        {AuthRoute({
          path: Routes.PROFILE,
          child: <ProfilePage />,
        })}
        {AuthRoute({
          path: Routes.REPORTS_POWERBI,
          child: (
            <Suspense fallback={<LazyLoadingWaiter />}>
              <ReportPage />
            </Suspense>
          ),
        })}
        {AuthRoute({
          path: Routes.ADMIN_SECURITY_PERMISSION_GROUPS,
          requiredPermissions: [Permission.ADMIN_PERMISSIONGROUPS_LIST_VIEW],
          child: <PermissionsPage />,
        })}
        {AuthRoute({
          path: Routes.ADMIN_SECURITY_PERMISSION_AUDIT,
          requiredPermissions: [Permission.ADMIN_PERMISSIONGROUPS_AUDIT],
          child: <PermissionAuditPage />,
        })}
        {AuthRoute({
          path: Routes.SUPPORT_FAILED_MEMBER_REGISTRATIONS,
          exact: true,
          requiredPermissions: [Permission.SUPPORT_ACTIONLOGS_FAILEDMEMBERREGISTRATIONS],
          child: <FailedMemberRegistrationsPage />,
        })}
        {AuthRoute({
          path: Routes.SUPPORT_MEMBER_SEARCH,
          exact: true,
          requiredPermissions: [Permission.SUPPORT_CLIENTMEMBERSEARCH_BASEACCESS],
          child: <MemberSearchPage />,
        })}
        {AuthRoute({
          path: Routes.SUPPORT_MEMBER_DETAIL,
          requiredPermissions: [Permission.SUPPORT_CLIENTMEMBERSEARCH_BASEACCESS],
          child: <MemberDetailPage />,
        })}
        {AuthRoute({
          path: Routes.ADMIN_TOOLS_COMMUNICATION_TESTER,
          requiredPermissions: [Permission.ADMIN_TOOLS_COMMUNICATIONTESTER],
          child: <CommunicationTesterPage />,
        })}
        {AuthRoute({
          path: Routes.DOCUMENTS_BASE,
          requiredPermissions: [Permission.DOCUMENTS_SIDENAV_ACCESS],
          child: <DocumentListPage />,
        })}
        {AuthRoute({
          path: Routes.DOCUMENTS_VIEW,
          requiredPermissions: [Permission.DOCUMENTS_SIDENAV_ACCESS],
          child: <DocumentViewer />,
        })}
        ;
        {AuthRoute({
          path: Routes.CLAIM_SEARCH,
          requiredPermissions: [Permission.TOOLS_CLAIMSEARCH_ACCESS],
          child: <ClaimSearchPage />,
        })}
        {AuthRoute({
          path: Routes.NOMINATION_SEARCH,
          requiredPermissions: [Permission.TOOLS_NOMINATIONSEARCH_ACCESS],
          child: <NominationSearchPage />,
        })}
        {AuthRoute({
          path: Routes.DOC_SCAN,
          requiredPermissions: [Permission.UTILITIES_DOCSCAN_ACCESS],
          child: <DocScanPage />,
        })}
        {AuthRoute({
          path: Routes.MAILROOM_FILE_LIST,
          requiredPermissions: [Permission.UTILITIES_MAILROOM_FILE_VIEW],
          exact: true,
          child: <MailroomFileListPage />,
        })}
        {AuthRoute({
          path: Routes.MAILROOM_FILE_DETAIL,
          requiredPermissions: [Permission.UTILITIES_MAILROOM_FILE_VIEW],
          child: <MailroomFileDetailPage />,
        })}
        {AuthRoute({
          path: Routes.MAILROOM_ACCOUNT_LIST,
          requiredPermissions: [Permission.UTILITIES_MAILROOM_ACCOUNT_VIEW],
          child: <MailroomAccountPage />,
        })}
        {AuthRoute({
          path: Routes.MAILROOM_FILESETUP,
          requiredPermissions: [Permission.UTILITIES_MAILROOM_FILESETUP_VIEW],
          child: <MailroomFileSetupDetailPage />,
        })}
        {AuthRoute({
          path: Routes.PRICING_TOOL,
          requiredPermissions: [Permission.TOOLS_PRICINGTOOL_ACCESS],
          child: <PricingToolPage />,
        })}
        {AuthRoute({
          path: Routes.PAVE_THE_WAY,
          requiredPermissions: [Permission.TOOLS_PAVETHEWAY_ACCESS],
          child: <PaveTheWayToolPage />,
        })}
        {/* This needs to be the last item */}
        <Route component={RedirectAs404} />
      </Switch>
    </AuthenticationProvider>
  );
};

export default App;
