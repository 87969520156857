import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Collapse, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import ProviderType from '../../../../consts/ProviderType';
import PnfMemberInfoDTO from '../../../../models/PnfMemberInfoDTO';
import Guid from '../../../../utils/Guid';
import { ProviderNominationProps } from '../../ProviderNomination';
import { MemberLookup } from './AppointmentFormListItem';
import ProviderForm from './ProviderForm';
import PnfProviderInfoDTO from '../../../../models/PnfProviderInfoDTO';
import confirmModal from '../../../../utils/Modal';

interface ProviderInfoProps extends ProviderNominationProps {
  editingId: string | null;
  setEditId: (id: string | null) => void;
  deleteId: (index: string) => void;
  isPrimary: boolean;
  add: () => void;
  saveEditing: () => Promise<boolean | undefined>;
  isPaveTheWayPage?: boolean;
}

const ProviderInfo = (props: ProviderInfoProps) => {
  const providers = props.pnf.providers ?? [];
  const [changesPending, setChangesPending] = useState(false);
  const [saved, setSaved] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  useEffect(() => {
    props.formRef?.current?.resetFields();
    setChangesPending(false);
  }, [props.editingId]);

  const getProviderTitle = (providerType: number, practitionerName: string, facilityName: string): string => {
    let title = '';
    if (providerType == ProviderType.CLINIC) {
      title = facilityName + `${practitionerName && ' (' + practitionerName + ')'}`;
    } else if (providerType === ProviderType.DOCTOR) {
      title = practitionerName + `${facilityName && ' (' + facilityName + ')'}`;
    }

    return title;
  };

  let activeKey: string | undefined = undefined;
  if (props.editingId) {
    const index = providers.findIndex((p) => p.id === props.editingId);
    activeKey = `provider_${index}`;
  }

  const disableContinue = () => {
    if (props.isPaveTheWayPage) {
      let hasAllFamilyMembers = false;
      providers.forEach((x) => {
        if (!hasAllFamilyMembers && x.appointments && x.appointments.length > 0) {
          x.appointments?.forEach((y) => {
            if (!hasAllFamilyMembers) {
              hasAllFamilyMembers = y.familyMemberId == null;
            }
          });
        } else {
          hasAllFamilyMembers = true;
        }
      });

      return hasAllFamilyMembers;
    }
  };

  const showErrorForFamilyMember = (provider: PnfProviderInfoDTO) => {
    if (props.isPaveTheWayPage) {
      if (provider.appointments && provider.appointments.length > 0) {
        if (provider.appointments.findIndex((x) => x.familyMemberId == null) > -1) {
          return <Alert message="Family Member not selected" type="error" showIcon />;
        }
      } else {
        return <Alert message="Appointment not set" type="error" showIcon />;
      }
    }
  };

  const validateProviders = (skipMultipleProviderValidation?: boolean) => {
    let hasValidationIssues = false;

    const currentProvider = props.formRef.current?.getFieldsValue();
    if (currentProvider && !skipMultipleProviderValidation) {
      return false;
    }

    providers.forEach((x) => {
      if (!hasValidationIssues && (!x.providerTypeId || !x.phoneNumber || !x.address)) {
        hasValidationIssues = true;
      }

      if (
        !hasValidationIssues &&
        ((x.providerTypeId == ProviderType.DOCTOR && !x.practitionerFirstName) ||
          (x.providerTypeId == ProviderType.CLINIC && !x.facilityName))
      ) {
        hasValidationIssues = true;
      }

      if (
        !hasValidationIssues &&
        x.address &&
        !x.address.address1 &&
        !x.address.city &&
        !x.address.state &&
        !x.address.zipCode
      ) {
        hasValidationIssues = true;
      }
    });

    if (!hasValidationIssues) {
      setSaved(false);
    }

    return hasValidationIssues;
  };

  const showValidationForProviders = (provider: PnfProviderInfoDTO) => {
    if (saved) {
      const currentProvider = props.formRef.current?.getFieldsValue();
      if (currentProvider && !showValidation) {
        return;
      }
      if (!provider.providerTypeId || !provider.phoneNumber || !provider.address) {
        return <Alert message="Validation Error" type="error" showIcon />;
      } else if (
        (provider.providerTypeId == ProviderType.DOCTOR && !provider.practitionerFirstName) ||
        (provider.providerTypeId == ProviderType.CLINIC && !provider.facilityName)
      ) {
        return <Alert message="Validation Error" type="error" showIcon />;
      } else if (
        provider.address &&
        !provider.address.address1 &&
        !provider.address.city &&
        !provider.address.state &&
        !provider.address.zipCode
      ) {
        return <Alert message="Validation Error" type="error" showIcon />;
      }
    }
  };

  const saveClicked = async () => {
    setSaved(true);
    setShowValidation(false);
    if (!validateProviders()) {
      if (props.saveEditing) {
        const validSave = await props.saveEditing();
        if (validSave) {
          setSaved(false);
        } else {
          validateProviders(true);
          setShowValidation(true);
        }
      }
    }
  };

  const deleteCondition = (id: string | null) => {
    return id !== Guid.EmptyGuid || (id === Guid.EmptyGuid && changesPending);
  };

  const continueCondition = () => {
    return (providers.filter((p) => p.id === Guid.EmptyGuid).length > 0 && !disableContinue()) || changesPending;
  };

  const confirmCancel = () => {
    if (props.editingId === Guid.EmptyGuid) {
      props.deleteId(Guid.EmptyGuid);
    } else {
      props.setEditId(null);
    }
  };

  const onAdd = () => {
    props.add();
    setChangesPending(true);
  }

  return (
    <>
      <h2>Healthcare Provider Information</h2>

      <Space direction="vertical" style={{ width: '100%' }}>
        {providers.map((p, i) => {
          return (
            <Collapse key={`provider_${i}`} activeKey={activeKey} destroyInactivePanel={true} bordered={false}>
              <Collapse.Panel
                key={`provider_${i}`}
                showArrow={false}
                header={
                  <Row align="middle">
                    <Col>
                      <h3 style={{ marginBottom: 0 }}>
                        {props.editingId === p.id
                          ? p.id === Guid.EmptyGuid
                            ? 'New Provider'
                            : getProviderTitle(
                                p.providerTypeId,
                                p.practitionerFirstName && p.practitionerLastName
                                  ? `${p.practitionerFirstName} ${p.practitionerLastName}`
                                  : '',
                                p.facilityName || ''
                              )
                          : getProviderTitle(
                              p.providerTypeId,
                              p.practitionerFirstName && p.practitionerLastName
                                ? `${p.practitionerFirstName} ${p.practitionerLastName}`
                                : '',
                              p.facilityName || ''
                            )}
                      </h3>
                    </Col>
                    <Col style={{ marginLeft: '10px', textAlign: 'right' }}>{showErrorForFamilyMember(p)}</Col>
                    <Col style={{ marginLeft: '10px', textAlign: 'right' }}>{showValidationForProviders(p)}</Col>
                    <Col flex={1} style={{ textAlign: 'right' }}>
                      <Button
                        icon={<EditOutlined />}
                        onClick={() =>
                          confirmModal(
                            'Unsaved changes, are you sure you want to continue?',
                            () => props.setEditId(p.id),
                            changesPending
                          )
                        }
                        type="link"
                        size="large"
                        shape="round"
                        disabled={props.editingId === p.id}
                      />

                      {providers.length > 1 ? (
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() =>
                            confirmModal(
                              'Are you sure you want to delete?',
                              () => props.deleteId(p.id ?? ''),
                              deleteCondition(p.id)
                            )
                          }
                          type="link"
                          size="large"
                          shape="round"
                        />
                      ) : null}
                    </Col>
                  </Row>
                }
              >
                <ProviderForm
                  formRef={props.formRef}
                  dto={p}
                  usStates={props.usStates}
                  isPrimary={props.isPrimary}
                  onChange={(values) => {
                    if (!changesPending) {
                      setChangesPending(true);
                      props.onChange(values);
                    }
                  }}
                  familyMembers={[
                    {
                      id: props.pnf.primaryMember?.id,
                      displayName: `${props.pnf.primaryMember?.firstName} ${props.pnf.primaryMember?.lastName}`,
                    } as MemberLookup,
                    ...(props.pnf.familyMembers ?? []).map((m: PnfMemberInfoDTO) => {
                      return {
                        id: m.id,
                        displayName: `${m.firstName} ${m.lastName} (${m.primaryRelationship})`,
                      } as MemberLookup;
                    }),
                  ]}
                />

                <Row justify="end" gutter={12}>
                  {providers.length > 1 || p.id !== Guid.EmptyGuid ? (
                    <Col flex={0}>
                      <Button
                        onClick={() =>
                          confirmModal('Are you sure you want to cancel?', () => confirmCancel(), changesPending)
                        }
                        shape="round"
                      >
                        Cancel
                      </Button>
                    </Col>
                  ) : null}
                </Row>
              </Collapse.Panel>
            </Collapse>
          );
        })}
      </Space>

      <Button
        type="ghost"
        size="large"
        shape="round"
        icon={<PlusOutlined />}
        style={{ marginTop: 20 }}
        onClick={onAdd}
        disabled={props.changesPending || props.editingId == Guid.EmptyGuid}
      >
        Add Provider
      </Button>
      <Row justify="space-between" style={{ paddingTop: '20px' }}>
        <Col flex="auto"></Col>
        <Col>
          <Space>
            <Button
              onClick={() =>
                confirmModal('There are unsaved changes, are you sure?', () => props.onBack(), props.changesPending)
              }
              size="large"
              shape="round"
            >
              Back
            </Button>
            <Button type="primary" size="large" shape="round" onClick={saveClicked}>
              Save
            </Button>

            <Button
              onClick={() =>
                confirmModal('There are unsaved changes, are you sure?', () => props.onContinue(), continueCondition())
              }
              type="primary"
              size="large"
              shape="round"
              disabled={providers.filter((p) => p.id !== Guid.EmptyGuid).length <= 0 || disableContinue()}
            >
              Continue
            </Button>
          </Space>
        </Col>
      </Row>
      {DEBUG ? (
        <div style={{ position: 'absolute', bottom: -50, right: 0 }}>
          <Button
            onClick={() => {
              props.formRef?.current?.setFieldsValue({
                practitionerFirstName: 'Joseph',
                practitionerLastName: 'Smith',
                facilityName: 'UPH',
                address: {
                  address1: '4300 Westtown Parkway',
                  city: 'Clive',
                  state: 'IA',
                  zipCode: '50266',
                },
                phoneNumber: '5154404960',
                website: 'qci.com',
                providerTypeId: ProviderType.DOCTOR,
              });
            }}
          >
            Fill Provider Info
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default ProviderInfo;
