// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface SupportMemberDetailDTO { 
    userId: number | null;
    pinId: number | null;
    pin: string | null;
    clientId: number;
    clientName: string | null;
    memberId: string | null;
    firstName: string | null;
    lastName: string | null;
    relationship: string | null;
    email: string | null;
    phone: string | null;
    userStatusName: string | null;
    hasRegistered: boolean;
    hasConfirmedEmail: boolean;
    hasMfaEnabled: boolean;
    createdOn: moment.Moment | string | null;
    endLockoutDate: moment.Moment | string | null;
    lastLoggedInOn: moment.Moment | string | null;
    registeredOn: moment.Moment | string | null;
}
const SupportMemberDetailDTO = {
    create: (initValues?: Partial<SupportMemberDetailDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            userId: null,
            pinId: null,
            pin: null,
            clientId: 0,
            clientName: null,
            memberId: null,
            firstName: null,
            lastName: null,
            relationship: null,
            email: null,
            phone: null,
            userStatusName: null,
            hasRegistered: false,
            hasConfirmedEmail: false,
            hasMfaEnabled: false,
            createdOn: null,
            endLockoutDate: null,
            lastLoggedInOn: null,
            registeredOn: null,
        },
        initValues);
    }
};

export default SupportMemberDetailDTO;