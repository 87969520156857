import { Button, Checkbox, Col, Form, FormInstance, Input, Result, Row, Space } from 'antd';
import React, { RefObject, useContext } from 'react';
import PnfDTO from '../../../../models/PnfDTO';
import { LanguageLookup, PnfSteps } from '../../ProviderNomination';
import ContactInfo from './ContactInfo';
import Providers from './Providers';
import { AuthenticationContext } from '../../../../auth/AuthenticationContext';
import AccessRole from '../../../../consts/AccessRole';
interface ReviewProps {
  pnf: PnfDTO;
  onBack?: () => void;
  formRef?: RefObject<FormInstance<any>>;
  onSubmit: () => void;
  goToStep?: (step: PnfSteps) => void;
  editProvider?: (id: string) => void;
  editMember?: (id: string) => void;
  onBehalf?: boolean;
  submitted?: boolean;
  isMobile?: boolean;
}

export const firstColProps = { xs: 24, sm: 11, xl: 12, xxl: 8 };
export const secondColProps = { xs: 24, sm: 13, xl: 12, xxl: 8 };

const Review = (props: ReviewProps) => {
  const authContext = useContext(AuthenticationContext);
  const isClaimDocEmployee = authContext.user?.accessRoleId === AccessRole.CLAIMDOC;

  const renderPrintPageButton = () => {
    if (props.isMobile) {
        return;
    }

    return (
      <Button shape="round" size="large" onClick={() => window.print()}>
        Print Page
      </Button>
    );
  };

  return (
    <>
      {props.submitted ? (
        <>
          <Result
            status="success"
            title="Submitted"
            subTitle={
              <>
                Find the underlined reference number next to each requested appointment. This reference number can be
                used to inquire about your nomination status.
              </>
            }
          />
        </>
      ) : (
        <>
          <h2>Review &amp; Submit</h2>
        </>
      )}

      <Space direction="vertical" style={{ width: '100%' }}>
        <ContactInfo pnf={props.pnf} goToStep={props.goToStep} editMember={props.editMember} />
        <Providers pnf={props.pnf} goToStep={props.goToStep} editProvider={props.editProvider} />

        {props.submitted && isClaimDocEmployee && (
          <>
            <Form ref={props.formRef} layout="vertical">
              <Form.Item name="pnfNote" label="Call Notes" initialValue={props.pnf.pnfNote}>
                <Input.TextArea disabled={true} />
              </Form.Item>
            </Form>
          </>
        )}
      </Space>

      {!props.submitted ? (
        <>
          <Form ref={props.formRef} layout="vertical">
            <Row justify="space-between" style={{ paddingBottom: '1vh' }}>
              {isClaimDocEmployee && (
                <Col xs={24}>
                  <Form.Item name="pnfNote" label="Call Notes">
                    <Input.TextArea maxLength={3000} showCount />
                  </Form.Item>
                </Col>
              )}
              {!props.onBehalf ? (
                <Col xs={24}>
                  <Form.Item name="includePdf" valuePropName="checked">
                    <Checkbox>
                      Please include an attachment including these nomination details with my email confirmation.
                    </Checkbox>
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
          </Form>
          <Row justify="space-between" style={{ paddingBottom: '5vh' }}>
            <Col flex="auto"></Col>
            <Col>
              <Space>
                <Button type="ghost" shape="round" size="large" onClick={props.onBack}>
                  Back
                </Button>
                <Button type="primary" shape="round" size="large" onClick={props.onSubmit}>
                  Submit
                </Button>
              </Space>
            </Col>
          </Row>
        </>
      ) : (
        <Row justify="space-between" style={{ paddingBottom: '5vh' }}>
          <Col flex="auto"></Col>
          <Col>
            <Space>
              {renderPrintPageButton()}
              <Button type="primary" shape="round" size="large" onClick={props.onSubmit}>
                {LanguageLookup.Submitted.StartOverText}
              </Button>
            </Space>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Review;
