import { LockOutlined, MailOutlined, UserAddOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Space,
  Spin,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AccountApiService from '../../api/AccountApiService';
import SupportApiService from '../../api/SupportApiService';
import PageTitle from '../../components/shared/PageTitle';
import MemberSupportAccountTypes from '../../consts/MemberSupportAccountTypes';
import Routes from '../../consts/Routes';
import useUrlQuery from '../../hooks/useUrlQuery';
import SupportMemberDetailDTO from '../../models/SupportMemberDetailDTO';
import { useForm } from 'antd/lib/form/Form';
import HistoryUtil from '../../utils/HistoryUtil';
import Permission from '../../consts/Permission';
import useHasPermission from '../../hooks/useHasPermission';

interface RouteProps {
  clientId?: string;
  userId?: string;
  pinId?: string;
}

const GetUserIdFromProps = (props: RouteProps) => {
  if (props.userId) {
    const parsed = parseInt(props.userId ?? '');
    return isNaN(parsed) ? null : parsed;
  }

  return null;
};

const GetPinIdFromProps = (props: RouteProps) => {
  if (props.pinId) {
    const parsed = parseInt(props.pinId ?? '');
    return isNaN(parsed) ? null : parsed;
  }

  return null;
};

const MemberDetailPage = () => {
  const [routeQuery] = useUrlQuery<RouteProps>();

  const [loading, setLoading] = useState(false);
  const [changesPending, setChangesPending] = useState(false);
  const [userId, setUserId] = useState<number | null>(null);
  const [pinId, setPinId] = useState<number | null>(null);
  const [form] = useForm();
  const [supportMember, setSupportMember] = useState<SupportMemberDetailDTO>(SupportMemberDetailDTO.create());
  const hasPermission = useHasPermission();

  const userEmail = supportMember.email ?? '';

  let pageTitle = '';
  if (userId) {
    pageTitle = `${MemberSupportAccountTypes.FULLACCOUNT} - ${supportMember.clientName}`;
  }

  if (pinId) {
    pageTitle = `${MemberSupportAccountTypes.GUESTPIN} - ${supportMember.clientName}`;
  }

  useEffect(() => {
    if (routeQuery.clientId) {
      if (routeQuery.userId) {
        setUserId(Number(routeQuery.userId));
      } else if (routeQuery.pinId) {
        setPinId(Number(routeQuery.pinId));
      }

      fetchSupportMemberDetail(
        Number(routeQuery.clientId),
        GetUserIdFromProps(routeQuery),
        GetPinIdFromProps(routeQuery)
      );
    }
  }, []);

  const fetchSupportMemberDetail = (clientId: number, userId: number | null, pinId: number | null) => {
    setLoading(true);
    SupportApiService.getSupportMemberDetail(clientId, userId, pinId)
      .then((res) => {
        setSupportMember(res);
        form.setFieldsValue(res);
      })
      .catch((err) => {
        notification.error({ message: err.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resendOriginalPinAccountEmail = () => {
    if (pinId && supportMember.email && supportMember.pin) {
      setLoading(true);
      SupportApiService.resendIndividualPinNotificationEmail(supportMember.email, supportMember.pin)
        .then(() => {
          notification.success({ message: 'Guest account welcome email sent.' });
        })
        .catch(() => {
          notification.error({ message: 'An error occurred while sending the guest account welcome email.' });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notification.error({ message: 'Invalid email or pin.' });
    }
  };

  const sendRegisterEmail = () => {
    if (pinId && supportMember.email) {
      setLoading(true);
      SupportApiService.sendRegistrationEmail(supportMember.email)
        .then(() => {
          notification.success({ message: 'Register email sent.' });
        })
        .catch(() => {
          notification.error({ message: 'An error occurred while sending the registration email.' });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notification.error({ message: 'Invalid email.' });
    }
  };

  const sendPasswordResetEmail = () => {
    if (userId && supportMember.email) {
      setLoading(true);
      AccountApiService.forgotPassword(supportMember.email, true)
        .then(() => {
          notification.success({ message: 'Password reset email sent.' });
        })
        .catch(() => {
          notification.error({ message: 'An error occurred while sending the password reset email.' });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notification.error({ message: 'Invalid email.' });
    }
  };

  const renderFullAccountDetail = () => {
    return (
      <>
        <Row gutter={[12, 0]}>
          <Col xs={12} xl={6}>
            <Form.Item name="email" label="Email">
              <Input disabled={readOnly} />
            </Form.Item>
          </Col>
          <Col xs={12} xl={6}>
            <Form.Item name="phone" label="Phone">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={12} xl={6}>
            <Form.Item name="userStatusName" label="User Status">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col xs={12} xl={6}>
            <Form.Item name="firstName" label="First Name">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={12} xl={6}>
            <Form.Item name="lastName" label="Last Name">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={12} xl={6}>
            <Form.Item name="relationship" label="Relationship">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col xs={12} xl={6}>
            <Form.Item name="hasRegistered" label="Has Registered?" valuePropName="checked">
              <Checkbox disabled />
            </Form.Item>
          </Col>
          <Col xs={12} xl={6}>
            <Form.Item name="hasConfirmedEmail" label="Has Confirmed Email?" valuePropName="checked">
              <Checkbox disabled />
            </Form.Item>
          </Col>
          <Col xs={12} xl={6}>
            <Form.Item name="hasMfaEnabled" label="MFA Enabled?" valuePropName="checked">
              <Checkbox disabled />
            </Form.Item>
          </Col>
        </Row>
        <Descriptions colon={false} column={{ xs: 2, xl: 4 }} layout="vertical" labelStyle={{ fontWeight: 'bold' }}>
          <Descriptions.Item label="Registered On">
            {supportMember.registeredOn ? moment(supportMember.registeredOn).format('MM/DD/yyyy hh:mm A') : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Last Successful Login">
            {supportMember.lastLoggedInOn ? moment(supportMember.lastLoggedInOn).format('MM/DD/yyyy hh:mm A') : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Lockout End">
            {supportMember.endLockoutDate ? moment(supportMember.endLockoutDate).format('MM/DD/yyyy hh:mm A') : ''}
          </Descriptions.Item>
        </Descriptions>
      </>
    );
  };

  const renderFullAccountActions = () => {
    return (
      <Row gutter={12}>
        <Col {...actionCardProps}>
          <Card style={{ textAlign: 'center' }}>
            <LockOutlined {...actionIconProps} />
            <br />
            <Button htmlType="button" disabled={loading} shape="round" onClick={() => sendPasswordResetEmail()}>
              Send Password Reset Email
            </Button>
          </Card>
        </Col>
      </Row>
    );
  };

  const renderPinAccountDetail = () => {
    return (
      <>
        <Row gutter={[12, 0]}>
          <Col xs={12} xl={6}>
            <Form.Item name="email" label="Email">
              <Input disabled={readOnly} />
            </Form.Item>
          </Col>
          <Col xs={12} xl={6}>
            <Form.Item name="firstName" label="First Name">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={12} xl={6}>
            <Form.Item name="lastName" label="Last Name">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Descriptions colon={false} column={{ xs: 2, xl: 4 }} layout="vertical" labelStyle={{ fontWeight: 'bold' }}>
          <Descriptions.Item label="Created On">
            {supportMember.createdOn ? moment(supportMember.createdOn).format('MM/DD/yyyy hh:mm A') : ''}
          </Descriptions.Item>
        </Descriptions>
      </>
    );
  };

  const renderPinAccountActions = () => {
    return (
      <Row gutter={12}>
        <Col {...actionCardProps}>
          <Card style={{ textAlign: 'center' }}>
            <MailOutlined {...actionIconProps} />
            <br />
            <Button htmlType="button" disabled={loading} shape="round" onClick={() => resendOriginalPinAccountEmail()}>
              Resend Welcome Email
            </Button>
          </Card>
        </Col>
        <Col {...actionCardProps}>
          <Card style={{ textAlign: 'center' }}>
            <UserAddOutlined {...actionIconProps} />
            <br />
            <Button htmlType="button" disabled={loading} shape="round" onClick={() => sendRegisterEmail()}>
              Send Registration Email
            </Button>
          </Card>
        </Col>
      </Row>
    );
  };

  const handleSubmit = () => {
    setChangesPending(false);
    setLoading(true);

    form.validateFields().then((values) => {
      SupportApiService.saveSupportMemberDetail({ ...supportMember, ...values })
        .then(() => {
          notification.success({ message: 'Account saved successfully' });
          fetchSupportMemberDetail(supportMember.clientId, supportMember.userId, supportMember.pinId);
        })
        .catch((e) => {
          notification.error({ message: e });
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const actionCardProps = { xxl: 5, xl: 8, lg: 10, md: 12, sm: 12, xs: 24, style: { marginBottom: 15 } };
  const actionIconProps = { style: { fontSize: 50, marginBottom: 15 } };

  const initialValues = {
    ...supportMember,
  };

  const readOnly = !hasPermission(Permission.SUPPORT_CLIENTMEMBERSEARCH_EDITMEMBER);

  return (
    <Content className="content-padding">
      <Row justify="space-between" style={{ marginBottom: '12px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={Routes.SUPPORT_MEMBER_SEARCH} style={{ textDecoration: 'underline', color: '#D2292D' }}>
              Client Member Search
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{userEmail}</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row style={{ height: '100%' }}>
        <Col xs={24} sm={24} md={24}>
          <Row className="content-header" justify="space-between">
            <Col flex={1}>
              <PageTitle title={pageTitle} style={{ marginTop: '0.5em', marginBottom: '0.5em' }} />
            </Col>
            {readOnly ? null : (
              <Col flex={0}>
                <Space>
                  <Button
                    shape="round"
                    onClick={() => {
                      HistoryUtil.push(Routes.SUPPORT_MEMBER_SEARCH);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!changesPending}
                    ghost
                    shape="round"
                    onClick={() => {
                      form.resetFields();
                      setChangesPending(false);
                    }}
                  >
                    Reset
                  </Button>
                  <Button disabled={!changesPending} type="primary" shape="round" onClick={handleSubmit}>
                    Save
                  </Button>
                </Space>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onValuesChange={() => setChangesPending(true)}
          onFinish={handleSubmit}
          style={{ marginTop: 10 }}
        >
          {userId && (
            <>
              {renderFullAccountDetail()}
              <Divider />
              {renderFullAccountActions()}
            </>
          )}
          {pinId && (
            <>
              {renderPinAccountDetail()}
              <Divider />
              {renderPinAccountActions()}
            </>
          )}
        </Form>
      </Spin>
    </Content>
  );
};

export default MemberDetailPage;
