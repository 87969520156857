import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Row, Select, Space } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Guid from '../../../../utils/Guid';
import { LanguageLookup, ProviderNominationProps } from '../../ProviderNomination';
import PersonalInfoForm from '../PersonalInfoForm';
import ProviderNominationPriorityOptions from '../../../../consts/ProviderNominationPriorityOptions';
import PnfMemberInfoDTO from '../../../../models/PnfMemberInfoDTO';
import UserApiService from '../../../../api/UserApiService';
import FamilyRelationshipTypes from '../../../../consts/FamilyRelationshipTypes';
import ProviderNominationsApiService from '../../../../api/ProviderNominationsApiService';
import MemberSearchDTO from '../../../../models/MemberSearchDTO';
import AddressDTO from '../../../../models/AddressDTO';
import confirmModal from '../../../../utils/Modal';

interface FamilyMembersProps extends ProviderNominationProps {
  editingId: string | null;
  setEditId: (index: string | null) => void;
  deleteId: (id: string) => void;
  updatePnf: (values: any, sendUpdate?: boolean) => void;
  add: () => void;
  saveEditing: () => void;
  updateFamilyPnf: (newFamily: PnfMemberInfoDTO[]) => void;
}

const FamilyMembers = (props: FamilyMembersProps) => {
  const BLANK_FAMILY_MEMBER_ID = '-1';

  const [allMembers, setAllMembers] = useState<PnfMemberInfoDTO[]>([]);
  const [changesPending, setChangesPending] = useState<boolean>(false);
  const [familyRelationship, setFamilyRelationship] = useState<number | undefined>(undefined);
  const [members, setMembers] = useState<MemberSearchDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedFamilyMember, setSelectedFamilyMember] = useState<PnfMemberInfoDTO | null>(
    PnfMemberInfoDTO.create({ memberId: undefined })
  );

  const propsFamilyMembers = (props.pnf?.familyMembers ?? []).filter((m) =>
    familyRelationship == FamilyRelationshipTypes.SPOUSE ? true : !m.isPrimary
  );
  const [familyMembers, setFamilyMembers] = useState<PnfMemberInfoDTO[]>([]);

  useEffect(() => {
    setLoading(true);
    const loaders = [];
    loaders.push(
      UserApiService.getFamilyRelationship().then((res) => {
        setFamilyRelationship(res);
      })
    );

    const tempAllMembers = [...allMembers];
    tempAllMembers.pushAll(propsFamilyMembers);
    tempAllMembers.push(props.pnf.primaryMember ?? PnfMemberInfoDTO.create());
    loaders.push(searchFamilyMemberApiCall(tempAllMembers));

    Promise.all(loaders).finally(() => {
      setLoading(false);
    });
    setAllMembers(tempAllMembers);
  }, []);

  useEffect(() => {
    props.formRef?.current?.resetFields();
    setChangesPending(false);
  }, [props.editingId]);

  useEffect(() => {
    setFamilyMembers(propsFamilyMembers);
  }, [props.pnf?.familyMembers, familyRelationship]);

  const searchFamilyMemberApiCall = (allMembers: PnfMemberInfoDTO[]) => {
    return ProviderNominationsApiService.getFamilyMembers(
      props.pnf.primaryMember?.memberId ?? '',
      props.pnf.memberAssociationClientId ?? 0,
      allMembers ?? []
    ).then((res) => {
      setMembers(res);
    });
  };

  const activeKey = props.editingId ? `memberCard_${props.editingId}` : undefined;
  const primaryFamilyRelationship = familyRelationship == FamilyRelationshipTypes.SPOUSE ? '(Spouse)' : '(Primary)';
  const loadedTitle = familyRelationship == undefined ? '' : primaryFamilyRelationship;

  const onFamilyMemberSelect = (memberId: string) => {
    const member = members.find((x) => x.claimDocId == memberId) ?? MemberSearchDTO.create();
    const selectedFamilyMember = PnfMemberInfoDTO.create({
      ...member,
      primaryRelationship: member.relationship,
      dateOfBirth: memberId == BLANK_FAMILY_MEMBER_ID ? null : moment(member.doB),
      phone: member.phoneNumber,
      memberId: memberId == null ? BLANK_FAMILY_MEMBER_ID : memberId,
      address: AddressDTO.create({
        address1: member.addressLine1,
        city: member.addressCity,
        state: member.state,
        zipCode: member.zipCode,
      }),
    });

    setSelectedFamilyMember(selectedFamilyMember);
  };

  const addFamilyMember = () => {
    const tempFamilyMembers = [...familyMembers];
    const tempAllMembers = [...allMembers];

    const familyMember = PnfMemberInfoDTO.create({ ...selectedFamilyMember });
    if (familyMember.memberId === BLANK_FAMILY_MEMBER_ID) {
      familyMember.memberId = null;
    }

    tempFamilyMembers.push(familyMember ?? PnfMemberInfoDTO.create());
    tempAllMembers.push(familyMember ?? PnfMemberInfoDTO.create());
    setAllMembers(tempAllMembers);
    setFamilyMembers(tempFamilyMembers);

    setSelectedFamilyMember(null);
    searchFamilyMemberApiCall(tempAllMembers);
    if (props.updateFamilyPnf) {
      props.updateFamilyPnf(tempFamilyMembers);
    }

    setChangesPending(true);
  };

  const memberOption = (member: MemberSearchDTO) => {
    return (
      <Select.Option key={member.claimDocId} value={member.claimDocId}>
        {member.firstName + ' ' + member.lastName + ' (' + member.relationship + ')'}
      </Select.Option>
    );
  };

  const memberOptions = () => {
    return members.map((x) => memberOption(x));
  };

  const renderAddFamilyMember = () => {
    return (
      <Space direction="vertical">
        Select a Family Member
        <Space direction="horizontal">
          <Select
            value={selectedFamilyMember?.memberId}
            style={{ minWidth: 200 }}
            showSearch
            optionFilterProp="children"
            allowClear={true}
            dropdownMatchSelectWidth={false}
            onSelect={onFamilyMemberSelect}
            disabled={props.editingId !== null || loading}
          >
            {memberOptions()}
            <Select.Option key={BLANK_FAMILY_MEMBER_ID} value={BLANK_FAMILY_MEMBER_ID}>
              EMPTY FAMILY MEMBER
            </Select.Option>
          </Select>
          <Button
            type="ghost"
            shape="round"
            onClick={addFamilyMember}
            disabled={changesPending || !selectedFamilyMember || selectedFamilyMember?.memberId === undefined || loading}
          >
            <PlusOutlined /> Add Family Member
          </Button>
        </Space>
      </Space>
    );
  };
  const deleteCondition = (id: string | null) => {
    return id !== Guid.EmptyGuid || (id === Guid.EmptyGuid && changesPending);
  };

  const confirmEdit = (id: string | null) => {
    if (props.editingId === Guid.EmptyGuid) {
      props.deleteId(Guid.EmptyGuid);
    }
    props.setEditId(id);
  };

  const confirmCancel = () => {
    if (props.editingId === Guid.EmptyGuid) {
      props.deleteId(Guid.EmptyGuid);
    } else {
      props.setEditId(null);
    }
  };

  return (
    <>
      <h2>{LanguageLookup.Steps.Family.Title}</h2>

      <Space direction="vertical" style={{ width: '100%' }}>
        <Collapse key="primary_member" destroyInactivePanel={true} bordered={false} collapsible="disabled">
          <Collapse.Panel
            key="primary_member_panel"
            showArrow={false}
            collapsible="icon"
            header={
              <Row align="middle">
                <Col>
                  <h3 style={{ marginBottom: 0 }}>
                    {`${props.pnf.primaryMember?.firstName} ${props.pnf.primaryMember?.lastName} ` + loadedTitle}
                  </h3>
                </Col>
                <Col flex={1} style={{ textAlign: 'right' }}>
                  <Button
                    icon={<DeleteOutlined />}
                    type="link"
                    size="large"
                    shape="round"
                    style={{ visibility: 'hidden' }}
                  />
                </Col>
              </Row>
            }
          ></Collapse.Panel>
        </Collapse>
        {familyMembers.map((member) => {
          return (
            <Collapse
              key={`memberCard_${member.id}`}
              activeKey={activeKey}
              destroyInactivePanel={true}
              bordered={false}
            >
              <Collapse.Panel
                key={`memberCard_${member.id}`}
                showArrow={false}
                collapsible="icon"
                header={
                  <Row align="middle">
                    <Col>
                      <h3 style={{ marginBottom: 0 }}>
                        {props.editingId === member.id
                          ? member.id === Guid.EmptyGuid
                            ? 'New Family Member'
                            : `${member.firstName} ${member.lastName} (${member.primaryRelationship})`
                          : `${member.firstName} ${member.lastName} (${member.primaryRelationship})`}
                      </h3>
                    </Col>
                    <Col flex={1} style={{ textAlign: 'right' }}>
                      {props.editingId !== member.id ? (
                        <Button
                          icon={<EditOutlined />}
                          onClick={() =>
                            confirmModal(
                              'Unsaved changes, are you sure you want to continue?',
                              () => confirmEdit(member.id),
                              changesPending
                            )
                          }
                          type="link"
                          size="large"
                          shape="round"
                        />
                      ) : null}
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() =>
                          confirmModal(
                            'Are you sure you want to delete?',
                            () => props.deleteId(member.id ?? ''),
                            deleteCondition(member.id)
                          )
                        }
                        type="link"
                        size="large"
                        shape="round"
                      />
                    </Col>
                  </Row>
                }
              >
                <PersonalInfoForm
                  formRef={props.formRef}
                  usStates={props.usStates}
                  pnf={props.pnf}
                  pnfMemberDTO={member}
                  allMembers={allMembers}
                  onBehalf={props.onBehalf}
                  updatePnf={props.updatePnf}
                  onChange={(values) => {
                    if (!changesPending) {
                      setChangesPending(true);
                      props.onChange(values);
                    }
                  }}
                />

                <Row justify="end" gutter={12}>
                  <Col flex={0}>
                    <Button
                      onClick={() =>
                        confirmModal('Are you sure you want to cancel?', () => confirmCancel(), changesPending)
                      }
                      shape="round"
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col flex={0}>
                    <Button type="primary" shape="round" onClick={props.saveEditing}>
                      Save
                    </Button>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          );
        })}
      </Space>
      {renderAddFamilyMember()}

      {DEBUG ? <Button onClick={() => setDebugFamilyValues(props.formRef?.current)}>Fill Family Form</Button> : null}

      <Row justify="end" style={{ paddingTop: '20px' }}>
        <Col flex={1} style={{ textAlign: 'right' }}>
          <Space>
            <Button
              onClick={() =>
                confirmModal('There are unsaved changes, are you sure?', () => props.onBack(), props.changesPending)
              }
              size="large"
              shape="round"
            >
              Back
            </Button>
            <Button
              onClick={() =>
                confirmModal('There are unsaved changes, are you sure?', () => props.onContinue(), props.changesPending)
              }
              type="primary"
              size="large"
              shape="round"
            >
              Continue
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

//#region Debug Helpers

const setDebugFamilyValues = (form: FormInstance | null) => {
  if (DEBUG) {
    form?.setFieldsValue({
      firstName: 'Little Jimmy',
      lastName: 'McKillip',
      dateOfBirth: moment('1/1/2000'),
      primaryRelationship: 'Dependent',
      detailedVoicemail: true,
      phone: '5154443322',
      email: 'test@test.com',
      address: {
        address1: '12 Dep Ln',
        address2: 'PO #234',
        city: 'WDM',
        state: 'IA',
        zipCode: '50000',
      },

      priority: ProviderNominationPriorityOptions.YES,
      contactMethods: ['Email'],
    });
  }
};
//#endregion

export default FamilyMembers;
